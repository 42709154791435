import React, { useState } from 'react'
import styled from 'styled-components'
import { boxShadow, Colors, MediaQuery } from '../styles'
import { FiMenu } from 'react-icons/fi'
import AppIcon from './AppIcon'
import { Link } from 'gatsby'
import ButtonSignup from './ButtonSignup'
import { Home, desktopNavLinks } from '../utils/nav'
import MobileMenu from './MobileMenu'

const Nav = styled.nav`
  font-family: 'Poppins', sans-serif;
  display: flex;
  background: #fff;
  width: 100%;
  align-items: center;
  /* justify-content: space-around; */
  ${boxShadow}
`

const ContentWrap = styled.div`
  padding: 20px;
  display: flex;
  flex: 1;
  align-items: center;
`

const Section = styled.div`
  display: flex;
  flex: 1;
`

// const Left = styled(Section)`
//   position: relative;
//   flex: auto;
//   @media ${MediaQuery.Small} {
//     justify-content: center;
//   }
// `

const TabletUpCenter = styled(Section)`
  display: none;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  @media ${MediaQuery.Medium} {
    display: flex;
  }
`

const SmallCenter = styled(Section)`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  @media ${MediaQuery.Medium} {
    display: none;
  }
`

const MobileMenuIcon = styled.div`
  @media ${MediaQuery.Small} {
    cursor: pointer;
    position: absolute;
    padding: 10px;
    top: -5px;
    left: 0;
  }
  @media ${MediaQuery.Medium} {
    /* cursor: pointer;
    align-self: center;
    margin-right: 20px; */
    display: none;
  }
  @media ${MediaQuery.Large} {
    display: none;
  }
`

const AppHeader: React.FC = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  return (
    <>
      <Nav>
        <ContentWrap>
          <SmallCenter>
            <MobileMenuIcon onClick={() => setShowMobileMenu(true)}>
              <FiMenu size={30} color={Colors.black} />
            </MobileMenuIcon>
            <Link to={Home.link}>
              <AppIcon />
            </Link>
          </SmallCenter>
          {/* <Left>
            <MobileMenuIcon onClick={() => setShowMobileMenu(true)}>
              <FiMenu size={30} color={Colors.black} />
            </MobileMenuIcon>
            <Link to={Home.link}>
              <AppIcon />
            </Link>
          </Left> */}
          <TabletUpCenter>
            <MobileMenuIcon onClick={() => setShowMobileMenu(true)}>
              <FiMenu size={30} color={Colors.black} />
            </MobileMenuIcon>
            <Link to={Home.link}>
              <AppIcon />
            </Link>
            {desktopNavLinks.map((x, i) => {
              return (
                <Link
                  key={i}
                  style={{ textTransform: 'uppercase' }}
                  to={x.link}
                >
                  {x.name}
                </Link>
              )
            })}
          </TabletUpCenter>
        </ContentWrap>
      </Nav>
      {showMobileMenu && (
        <MobileMenu
          showMobileMenu={showMobileMenu}
          setShowMobileMenu={setShowMobileMenu}
        />
      )}
    </>
  )
}

export default AppHeader
