import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { boxShadow, Colors, MediaQuery } from '../styles'
import { FiX } from 'react-icons/fi'
import AppIcon from './AppIcon'
import { Link } from 'gatsby'
import ButtonSignup from './ButtonSignup'
import { Home, desktopNavLinks, footerLinks } from '../utils/nav'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock'

const Menu = styled.div`
  font-family: 'Poppins', sans-serif;
  -webkit-overflow-scrolling: touch;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1;
  background: #fff;

  /* justify-content: space-around; */

  @media ${MediaQuery.Small} {
    /* position: fixed; */
  }
  @media ${MediaQuery.Medium} {
    /* position: fixed; */
  }
  @media ${MediaQuery.Large} {
    /* position: relative; */
  }
`

const LinkWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* padding: 10px; */
  background: ${Colors.blue};
  font-size: 1rem;
  justify-content: center;
  a {
    padding: 25px 10px;
    color: ${Colors.white};
    border-bottom: solid 1px #fff;
  }
`

const AppIconWrap = styled.div`
  position: relative;
  text-align: center;
  padding: 40px;
`

const CloseIcon = styled.div`
  cursor: pointer;
  position: absolute;
  padding: 20px;
  top: 0px;
  left: 0;
  z-index: 1;
`

interface Props {
  showMobileMenu: boolean
  setShowMobileMenu: (show: boolean) => void
}

const MobileMenu: React.FC<Props> = ({ showMobileMenu, setShowMobileMenu }) => {
  const targetElement = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (targetElement.current) {
      disableBodyScroll(targetElement.current)
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  const onDismiss = () => {
    setShowMobileMenu(false)
  }

  return (
    <Menu ref={targetElement} id="mobile-menu">
      <CloseIcon onClick={onDismiss}>
        <FiX size={30} color={Colors.black} />
      </CloseIcon>
      <AppIconWrap>
        <Link onClick={onDismiss} to={Home.link}>
          <AppIcon style={{ width: 75 }} />
        </Link>
      </AppIconWrap>
      <LinkWrap>
        {footerLinks.map((x, i) => {
          return (
            <Link onClick={onDismiss} key={i} to={x.link || '/'}>
              {x.name}
            </Link>
          )
        })}
      </LinkWrap>
    </Menu>
  )
}

export default MobileMenu
