export interface NavLink {
  external: boolean
  name: string
  link: string
}

export const InstallNow: NavLink = {
  name: 'Install Now',
  link: '/download',
  external: false
}

export const AppStoreDownload: NavLink = {
  name: 'Login or Sign up',
  link: process.env.GATSBY_APP_STORE_URL || '',
  external: true
}

export const PlayStoreDownload: NavLink = {
  name: 'Login or Sign up',
  link: process.env.GATSBY_PLAY_STORE_URL || '',
  external: true
}

export const CometLoop: NavLink = {
  name: 'Cometloop.com',
  link: process.env.GATSBY_COMETLOOP_URL || '',
  external: true
}

export const Support: NavLink = {
  name: 'Support',
  link: `${process.env.GATSBY_COMETLOOP_URL}/contact`,
  external: true
}

export const FAQ: NavLink = {
  name: 'FAQ',
  link: `/faq`,
  external: false
}

export const PrivacyPolicy: NavLink = {
  name: 'Privacy Policy',
  link: '/privacy-policy',
  external: true
}

export const TermsAndConditions: NavLink = {
  name: 'Terms and Conditions',
  link: '/terms-and-conditions',
  external: true
}

export const Disclaimer: NavLink = {
  name: 'Disclaimer',
  link: '/disclaimer',
  external: true
}

export const Home: NavLink = {
  name: 'Home',
  link: '/',
  external: false
}

export const HowItWorks: NavLink = {
  name: 'How it Works',
  link: '/how-it-works',
  external: false
}

export const Pricing: NavLink = {
  name: 'Pricing',
  link: '/pricing',
  external: false
}

export const desktopNavLinks = [InstallNow, HowItWorks, Pricing, FAQ, Support]

export const footerLinks = [
  Home,
  InstallNow,
  HowItWorks,
  Pricing,
  FAQ,
  Support,
  PrivacyPolicy,
  TermsAndConditions,
  Disclaimer,
  CometLoop
]

export const mobileLinks = [
  Home,
  InstallNow,
  HowItWorks,
  Pricing,
  FAQ,
  Support,
  PrivacyPolicy,
  TermsAndConditions,
  Disclaimer,
  CometLoop
]
