import React from 'react'
import styled from 'styled-components'
import { Colors, MediaQuery } from '../styles'
import { Link } from 'gatsby'
import { CometLoop, footerLinks } from '../utils/nav'
import DownloadButtons from './DownloadButtons'
import { StaticImage } from 'gatsby-plugin-image'

const FooterWrap = styled.footer`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;

  background-color: ${Colors.black};
  a {
    color: #fff;
    display: block;
    text-align: center;
  }
`

const CometLoopImg = styled.div`
  margin: 0 auto;
  margin-bottom: 20px;
  width: 70px;
  height: 70px;
  @media ${MediaQuery.Medium} {
    width: 100px;
    height: 100px;
  }
`

const Copyright = styled.p`
  padding: 30px 0 0 0;
  color: #fff;
  font-size: 1rem;
`

const LinkWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 0 0 0;
  color: #fff;
  font-size: 1rem;
  justify-content: center;
  a {
    padding: 5px 10px;
  }
`

const DlWrap = styled.div`
  width: 600px;
`

const Footer: React.FC = () => {
  return (
    <FooterWrap>
      {/* <DlWrap>
        <DownloadButtons />
      </DlWrap> */}

      <a
        style={{ display: 'block', textAlign: 'center' }}
        href={CometLoop.link}
      >
        <CometLoopImg>
          <StaticImage
            src="https://cometloop.com/logo-white-transparent-200px.png"
            alt="https://cometloop.com"
          />
        </CometLoopImg>
        <p>Powered by Comet Loop</p>
      </a>

      <LinkWrap>
        {footerLinks.map((x, i) => {
          return (
            <Link key={i} to={x.link || '/'}>
              {x.name}
            </Link>
          )
        })}
      </LinkWrap>
      <Copyright>&copy; 2021 Comet Loop LLC.</Copyright>
    </FooterWrap>
  )
}

export default Footer
