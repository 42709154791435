import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { MediaQuery } from '../styles'

const Wrap = styled.div`
  width: 30px;
  @media ${MediaQuery.Medium} {
    width: 40px;
  }
`

interface Props {
  style?: any
}

const AppIcon: React.FC<Props> = (props) => {
  return (
    <Wrap {...props}>
      <StaticImage
        style={{ width: '100%', height: '100%' }}
        src="../../static/app-icon.png"
        alt="My School Files"
      />
    </Wrap>
  )
}

export default AppIcon
