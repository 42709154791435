/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'
import AppHeader from './AppHeader'
import { GlobalStyle } from '../styles'
import Footer from './Footer'
import FreeTrialBanner from './FreeTrialBanner'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const TopFixed = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`

const MainBody = styled.div`
  /* position: relative; */
  margin-top: 120px;
`

const Layout: React.FC = ({ children }) => {
  return (
    <Container>
      <GlobalStyle />
      <TopFixed>
        <FreeTrialBanner />
        <AppHeader />
      </TopFixed>
      <MainBody>
        <main>{children}</main>
      </MainBody>
      <Footer />
    </Container>
  )
}

export default Layout
