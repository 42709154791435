import styled from 'styled-components'
import { MediaQuery } from './breakpoints'
import { Colors } from './colors'

export * from './colors'
export * from './breakpoints'
export * from './globalStyles'
export * from './mixins'
export * from './Grid'

export const H1 = styled.h1`
  font-weight: 700;
  font-size: 2rem;
  text-transform: uppercase;
  @media ${MediaQuery.Medium} {
    font-size: 2.5rem;
  }
  @media ${MediaQuery.Large} {
    font-size: 3.3rem;
  }
`

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 2.3rem;
  text-transform: uppercase;
`

export const Text = styled.p`
  font-weight: normal;
  font-size: 1.2rem;
  @media ${MediaQuery.Medium} {
    font-size: 1.7rem;
  }
`

export const FootnoteText = styled.p`
  font-weight: normal;
  font-size: 0.8rem;
  @media ${MediaQuery.Medium} {
    font-size: 1rem;
  }
`

export const Button = styled.a`
  display: inline-flex;
  font-family: arial;
  font-weight: bold;
  color: #000;

  color: #fff;

  height: 50px;
  font-size: 1.25rem;

  text-transform: uppercase;
  border-radius: 50px;
  /* background: gradient(
    linear,
    left bottom,
    left top,
    from(#4caf50),
    to(#5bc15f)
  ); */
  /* background: linear-gradient(to top, #4caf50, #5bc15f); */
  background: ${Colors.blue};
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  @media ${MediaQuery.Medium} {
    height: 60px;
    font-size: 1.5rem;
  }
  @media ${MediaQuery.Large} {
    height: 80px;
    font-size: 1.5rem;
  }
`

export const SinglePhoneImage = styled.img`
  width: 60%;
  @media ${MediaQuery.Medium} {
    width: 50%;
  }
  @media ${MediaQuery.Large} {
    width: 50%;
  }
`

export const SinglePhoneImageWrap = styled.div`
  width: 60%;
  @media ${MediaQuery.Medium} {
    width: 50%;
  }
  @media ${MediaQuery.Large} {
    width: 50%;
  }
`

export const WiderPhoneImage = styled.img`
  width: 55%;
  @media ${MediaQuery.Medium} {
    width: 45%;
  }
  @media ${MediaQuery.Large} {
    width: 60%;
  }
`

export const UltraWiderPhoneImage = styled.img`
  width: 70%;
  @media ${MediaQuery.Medium} {
    width: 50%;
  }
  @media ${MediaQuery.Large} {
    width: 85%;
  }
`

export const UltraWiderPhoneImageWrap = styled.div`
  width: 70%;
  @media ${MediaQuery.Medium} {
    width: 50%;
  }
  @media ${MediaQuery.Large} {
    width: 85%;
  }
`

export const List = styled.ul`
  list-style: disc;
  margin: 0 20px;
  font-weight: normal;
  font-size: 1.2rem;
  margin: 0 50px;
  span {
    font-weight: bold;
  }
  @media ${MediaQuery.Medium} {
    font-size: 1.7rem;
    margin: 0 80px;
  }
  @media ${MediaQuery.Large} {
    font-size: 1.7rem;
    margin: 0 30px;
  }
  li {
    padding: 10px 0;
  }
`
